var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var Bfa,Cfa,Dfa,Efa,Ffa,Gfa,Hfa,Ifa,Jfa;Bfa=function(a,b){a.sort(b||$CLJS.Da)};Cfa=function(a,b){const c=Array(a.length);for(let e=0;e<a.length;e++)c[e]={index:e,value:a[e]};const d=b||$CLJS.Da;Bfa(c,function(e,f){return d(e.value,f.value)||e.index-f.index});for(b=0;b<a.length;b++)a[b]=c[b].value};
$CLJS.TG=function(a){return $CLJS.E.g(a,$CLJS.Jd)?$CLJS.Jd:function(b,c){var d=a.g?a.g(b,c):a.call(null,b,c);return"number"===typeof d?d:$CLJS.n(d)?-1:$CLJS.n(a.g?a.g(c,b):a.call(null,c,b))?1:0}};$CLJS.UG=function(a,b){if($CLJS.y(b)){var c=$CLJS.we.h?$CLJS.we.h(b):$CLJS.we.call(null,b);Cfa(c,$CLJS.TG(a));return $CLJS.od($CLJS.y(c),$CLJS.pd(b))}return $CLJS.Kc};$CLJS.VG=new $CLJS.M(null,"second","second",-444702010);$CLJS.WG=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);
$CLJS.XG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.YG=new $CLJS.M(null,"millisecond","millisecond",-540123566);$CLJS.ZG=new $CLJS.M(null,"year-of-era","year-of-era",682445876);Dfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);
Efa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.$G=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.bH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);
Ffa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);Gfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);Hfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);
Ifa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);$CLJS.dH=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.fH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);
Jfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var gH=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Ii,$CLJS.Kj,$CLJS.Ij,$CLJS.qi,$CLJS.rk,$CLJS.Ci,$CLJS.ji,$CLJS.ZG],null),hH=$CLJS.fh(gH),Kfa,Lfa,Mfa;$CLJS.X(Hfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,1,[$CLJS.pt,"Valid date extraction unit"],null)],null),hH));var iH=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Yt,$CLJS.sj,$CLJS.Bj,$CLJS.pi,$CLJS.ji],null),jH=$CLJS.fh(iH);
$CLJS.X(Gfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,1,[$CLJS.pt,"Valid date truncation unit"],null)],null),jH));$CLJS.kH=$CLJS.eg.j($CLJS.Cf,$CLJS.hl.o(),$CLJS.gf.g(iH,gH));Kfa=$CLJS.fh($CLJS.kH);$CLJS.X($CLJS.bH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,1,[$CLJS.pt,"Valid date bucketing unit"],null)],null),Kfa));var lH=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WG,$CLJS.mj,$CLJS.wi],null),mH=$CLJS.fh(lH);
$CLJS.X(Ffa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,1,[$CLJS.pt,"Valid time extraction unit"],null)],null),mH));var nH=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.YG,$CLJS.VG,$CLJS.Xi,$CLJS.vk],null),oH=$CLJS.fh(nH);$CLJS.X(Jfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,1,[$CLJS.pt,"Valid time truncation unit"],null)],null),oH));$CLJS.pH=$CLJS.eg.j($CLJS.Cf,$CLJS.hl.o(),$CLJS.gf.g(nH,lH));Lfa=$CLJS.fh($CLJS.pH);
$CLJS.X(Efa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,1,[$CLJS.pt,"Valid time bucketing unit"],null)],null),Lfa));$CLJS.qH=$CLJS.eg.j($CLJS.Cf,$CLJS.hl.o(),$CLJS.gf.l(nH,iH,$CLJS.H([lH,gH])));Mfa=$CLJS.fh($CLJS.qH);$CLJS.X($CLJS.XG,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,1,[$CLJS.pt,"Valid datetime bucketing unit"],null)],null),$CLJS.qH));var Nfa=$CLJS.be.g(Mfa,$CLJS.ci);
$CLJS.X($CLJS.eH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,1,[$CLJS.pt,"Valid temporal bucketing unit"],null)],null),Nfa));$CLJS.rH=$CLJS.Zt.g(jH,oH);$CLJS.X($CLJS.$G,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,1,[$CLJS.pt,"Valid datetime truncation unit"],null)],null),$CLJS.rH));$CLJS.sH=$CLJS.Zt.g(hH,mH);$CLJS.X(Dfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,1,[$CLJS.pt,"Valid datetime extraction unit"],null)],null),$CLJS.sH));
var tH=$CLJS.be.g(jH,$CLJS.ji);$CLJS.X($CLJS.fH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,1,[$CLJS.pt,"Valid date interval unit"],null)],null),tH));$CLJS.X($CLJS.aH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,1,[$CLJS.pt,"Valid time interval unit"],null)],null),oH));var Ofa=$CLJS.Zt.g(tH,oH);$CLJS.X($CLJS.cH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,1,[$CLJS.pt,"Valid datetime interval unit"],null)],null),Ofa));
$CLJS.X(Ifa,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.nk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$r,$CLJS.dH],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.si,$CLJS.eH],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Js,!0],null),$CLJS.Gs],null)],null));